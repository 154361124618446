import { Helmet } from "react-helmet";
import RoundedSection from "../components/atoms/RoundedSection";
import SubmitButton from "../components/atoms/SubmitButton";
import Footer from "../components/organisms/Footer";
import SSOFeatureSelector from "../components/organisms/SSOFeatureSelector";
import Navbar from "../components/organisms/navbar";
import ProofButton from "../components/organisms/ProofButton";

function SingleSignOn() {
  return (
    <div className="min-h-full w-full overflow-hidden px-6 lg:px-12">
      <Helmet>
        <title>Aglide SAMLless SSO</title>
        <meta name="description" content="See how Aglide can manage access to any app - no SAML, no SSO tax." />
        <meta property="og:image" content="" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Aglide SAMLless SSO",
            "description": "See how Aglide can manage access to any app - no SAML, no SSO tax.",
            "image": ""
          }
        `}
        </script>
      </Helmet>
      <Navbar />
      <div className="max-w-7xl mx-auto py-48 mt-8 flex flex-col md:flex-row gap-32">
        <div className="flex flex-col align-left lg:w-[66%] w-[100%] gap-1">
          <h3 className="mx-1 lg:text-2xl text-xl font-medium text-primary">
            Single Sign On
          </h3>
          <h1 className="lg:text-7xl text-4xl font-medium text-black mb-4 mt-1">
            SSO, with none of the SAML hassle.
          </h1>
          <h3 className="lg:text-2xl text-xl text-darkgrey">
            Just like with SAML, Aglide SSO lets admins manage user access and end-users sign in to their apps with their SSO credentials only.</h3>
          <div className="mt-8 flex gap-4">
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full gap-48 mb-36">
        <div className="h-fit max-w-7xl align-middle mx-auto overflow-visible">
          <h1 className="text-center lg:text-4xl text-2xl font-medium text-black">
            Extend SSO further then it has been before.
          </h1>
          <div className="max-w-7xl grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 align-middle mx-auto mt-8">
            <RoundedSection
              title="Apps without SAML."
              body="Manage access to all the apps that don't support SAML, without compromising on security."
              image="/media/SAMLlessApps.png"
            />
            <RoundedSection
              title="Shared accounts."
              body="Stop sharing credentials, and start enforcing conditional access and instant revoking to shared accounts."
              image="/media/SharedApps.png"
            />
            <RoundedSection
              title="High SSO tax."
              body="Enable SSO for all the services where it was previously too expensive to upgrade to enterprise."
              image="/media/SSOTaxApps.png"
            />
          </div>
        </div>

        <div className="h-fit max-w-7xl align-middle mx-auto overflow-visible">
          <div className="w-full flex flex-col lg:flex-row align-middle mx-auto">
            <div className="flex lg:w-[40%] w-full flex-col lg:mt-8 gap-2 lg:gap-8">
              <h1 className="text-left lg:text-4xl text-2xl font-medium text-black">
                How it works
              </h1>
              <div className="flex flex-col gap-4">
                <p className="text-left lg:text-2xl text-lg text-darkgrey">
                  Aglide is powered by our proprietary access formation technology - letting Aglide Desktop authenticate any browser or desktop app.
                </p>
                <p className="text-left lg:text-2xl text-lg text-darkgrey">
                  Unlike with our competitors, <b>end users (& their browsers) can't ever see or change account credentials.</b>
                </p>
              </div>
              <div className="hidden lg:flex">
                <ProofButton />
              </div>
            </div>

            <div
              className={`w-full lg:w-fill lg:mr-[-50px] m-auto align-middle `}
            >
              <div className="w-full hidden lg:flex">
                <img
                  src={"/media/SSOLoading.png"}
                  className="mx-auto"
                  alt="onboarding"
                />
              </div>
            </div>
            <div
              className={`flex lg:hidden align-middle mt-8 mb-2 m-[-32px]`}
            >

              <img
                src={"/media/SSOLoading.png"}
                className="mx-auto"
                alt="onboarding"
              />
            </div>
            <div className="flex lg:hidden w-full justify-end">
              <ProofButton />
            </div>
          </div>
        </div>

        <SSOFeatureSelector />

        <div className="max-w-7xl min-h-[860px] h-fit w-full flex flex-col md:flex-row align-middle mx-auto relative">
          <div className="w-[100%] md:w-[60%] lg:w-[64%] h-fit overflow-x-hidden">
            <div className="hidden md:flex w-[1200px] h-[] absolute top-0 right-0">
              <img
                src={"/media/SSOCredentials.png"}
                className="mx-auto"
                alt={"dashboard-SSOCredentials"}
                style={{ transform: "translateX(-38%)" }}
              />
            </div>
          </div>
          <div className="flex md:w-[63%] lg:w-[30%] w-full flex-col mt-8 gap-2 lg:gap-4 z-10">
            <h1 className="text-left lg:text-3xl text-xl font-medium text-black mb-2">
              Connect accounts with just usernames and passwords.
            </h1>
            <p className="text-left lg:text-2xl text-lg text-darkgrey">
              Connecting an account to Aglide SSO is as simple as storing it's username and password in our secure zero trust vaults.
            </p>
            <p className="text-left lg:text-2xl text-lg text-darkgrey">
              When credentials are stored in Aglide, it's impossible for end users to view or update them.
            </p>
            <div className="mt-2 hidden md:flex">
              <SubmitButton
                type="primary"
                text="Security"
                onClick={() =>
                  (window.location.href = "/security")
                }
              />
            </div>
          </div>
          <div className="flex md:hidden m-[-24px] mt-8">
            <img
              src={"/media/SSOCredentialsSM.png"}
              className="mx-auto"
              alt="onboarding"
            />
          </div>
          <div className="mt-8 justify-end flex md:hidden">
            <SubmitButton
              type="primary"
              text="Security"
              onClick={() =>
                (window.location.href = "/security")
              }
            />
          </div>
        </div>

        <div className="bg-primary5 rounded-2xl p-6 md:p-14 flex flex-col lg:gap-16 gap-8 h-fit max-w-5xl mx-auto md:flex-row w-full">
          <div className="flex-1 order-1">
            <h1 className="text-left lg:text-3xl text-xl font-medium text-black mb-2">
              Want to know more?
            </h1>
            <p className="text-left text-l text-darkgrey">
              The exact mechanics of Aglide SSO are commercially sensitive. However, we would be delighted to share more detail in a demo.
            </p>
            <div className="flex flex-col sm:flex-row justify-left gap-4 mr-auto mt-6">
              <SubmitButton
                type="primary"
                text="Book a demo"
                onClick={() =>
                  (window.location.href = "/book-demo")
                }
              />
            </div>
          </div>
          <div className="md:w-1/2 w-full order-2 sm:max-w-[400px] mx-auto">
            <img src={"/media/HowItWorks/Launch.png"} alt="onboarding" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SingleSignOn;
