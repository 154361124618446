import React, { useState } from "react";
import { IoMdPlay, IoMdPlayCircle } from "react-icons/io";
import PillButton from "../components/atoms/PillButton";
import SubmitButton from "../components/atoms/SubmitButton";
import Footer from "../components/organisms/Footer";
import HomeSection from "../components/atoms/FeatureSection";
import Navbar from "../components/organisms/navbar";
import RoundedSection from "../components/atoms/RoundedSection";
import { Helmet } from "react-helmet";

function Security() {
  return (
    <div className="min-h-full w-full overflow-hidden px-6 lg:px-12">
      <Helmet>
        <title>Security</title>
        <meta name="description" content="Learn how Aglide's Zero Trust Architecture keeps your accounts secure" />
        <meta property="og:image" content="" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Security",
            "description": "Learn how Aglide's Zero Trust Architecture keeps your accounts secure",
            "image": ""
          }
        `}
        </script>
      </Helmet>

      <Navbar />

      <div className="max-w-7xl mx-auto py-48 mt-8 flex flex-col md:flex-row gap-32">
        <div className="flex flex-col align-left lg:w-[66%] w-[100%] gap-1">
          <h3 className="mx-1 lg:text-2xl text-xl font-medium text-primary">
            Zero knowledge architecture
          </h3>
          <h1 className="lg:text-7xl text-4xl font-medium text-black mb-4 mt-1">
            Aglide Security
          </h1>
          <h3 className="lg:text-2xl text-xl text-darkgrey">
            Aglide was built with a state of the art zero knowledge
            architecture, meaning no one can read your data, not even Aglide.
            This page details how we keep your data secure.
          </h3>
          <div className="mt-8 flex gap-4"></div>
        </div>
      </div>

      <div className="flex flex-col gap-36 w-full ">
        <div className="max-w-7xl mx-auto flex flex-col gap-2" id="sso">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1 className="text-left lg:text-4xl text-2xl font-medium text-black">
              The TLDR
            </h1>
            <p className="text-left lg:text-2xl text-lg text-darkgrey">
              This page outlines the engineering practices we use to protect your data. 
              Our specific Security Policy can be found at aglide.com/security-policy. 
            </p>
            <p className="text-left lg:text-2xl text-lg text-darkgrey">  
              Simply put, we take every step we can to ensure your data is
              protected and only accessible by you. Security is at the core of
              every product and development decision we make. We're building
              in-line with SOC2-Type2 compliance. We've already engaged with a
              third party who will help audit this and aim to have our
              certification by Q4 2024.
            </p>
          </div>
          <div className="max-w-7xl grid lg:grid-cols-4 grid-cols-1 gap-8 align-middle mx-auto my-4">
            <RoundedSection
              title="Zero-knowledge"
              body="Your Aglide data can only be decrypted using a key generated from your password. Your password is never stored, meaning neither an attacker nor us can ever decrypt it."
            />
            <RoundedSection
              title="Gov. grade encrpytion"
              body="We use the AES-256 encryption standard, also used by the US government, considered to be virtually unbreakable."
            />
            <RoundedSection
              title="Industry leading hashing"
              body="For all users, we enforce industry-leading hashing (Argon2id), with higher than recommended configuration, to store your master password."
            />
            <RoundedSection
              title="Protect everything"
              body="We don’t just encrypt your passwords and usernames. Every piece of information related to you is stored in a secured format."
            />
          </div>
        </div>

        <div className="max-w-7xl mx-auto" id="sso">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1 className="text-left lg:text-4xl text-2xl font-medium text-black">
              1. How your data is stored.
            </h1>
            <p className="text-left lg:text-2xl text-lg text-darkgrey">
              All personal details, organisation details, and stored
              account/item details are encrypted using AES-CBC 256-bit
              encryption. Effectively everything that can be encrypted is
              encrypted, and it's encryped with keys that can only be generated
              by the end user, meaning they are the only one who can access it.
              Other information such as a user's master password, that never
              needs to be retrieved but only verified against, is hashed. There
              are six types of encrypted data in the Aglide database:
            </p>
            <div className="max-w-7xl grid lg:grid-cols-3 grid-cols-1 gap-8 align-middle mx-auto my-4">
              <RoundedSection
                title="Personal Server"
                body="Personal data encrypted with a user’s personal server-side encryption key. E.g. login and other personal item details."
              />
              <RoundedSection
                title="Personal Local"
                body="Personal data encrypted with a user’s personal local encryption key. E.g. TOTP secrets."
              />
              <RoundedSection
                title="Vault Server"
                body="Vault data encrypted with a vault’s server-side encryption key. E.g. items stored inside a shared vault."
              />
              <RoundedSection
                title="Vault Local"
                body="Vault data encrypted with a vault’s local encryption key. E.g. vault TOTP secrets."
              />
              <RoundedSection
                title="Organisation"
                body="Organisation data encrypted with an organisation’s encryption key. E.g. an organisation’s details, or organisation level app details (billing frequency etc)."
              />
              <RoundedSection
                title="Administrative"
                body="Administrative data encrypted with a server-wide encryption key, stored in our own secrets vault. E.g. a user’s work email, or stripe subscription ID. This less sensitive information is the only information Aglide can read and is kept to a bare minimum."
              />
            </div>
            <div className="flex w-full flex-col gap-2 lg:gap-4">
              <h1 className="whitespace-normal text-xl font-medium text-black">
                Generating Keys
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                The personal, vault and organisation data encryption keys (DEKs)
                are never stored in plaintext, and are instead always encrypted
                with a key encryption key (KEK) before storage. For the vault
                and organisation keys, the KEK is the user’s master encryption
                key, and for the user’s key itself it is protected by a key
                generated from a user’s login password. Similarly in order for
                an admin to access a managed user’s account details, they
                require a user’s master encryption key. This is stored in their
                instance encrypted by their own master encryption key.
              </p>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1 className="text-left lg:text-4xl text-2xl font-medium text-black">
              2. How your data is accessed
            </h1>
            <p className="text-left lg:text-2xl text-lg text-darkgrey">
              Your personal encryption keys are protected by a key encryption
              key generated from your plain text password. Your plain text
              password, and the key generated from it, are never stored, meaning
              it is impossible for us (or an attacker) to access your or your
              organisation’s data. This is known as a zero-knowledge model.
            </p>

            <div className="flex w-full flex-col mt-4 gap-2 lg:gap-4">
              <h1 className="whitespace-normal text-xl font-medium text-black">
                Authorizing users
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                When you set your password, your password is hashed using the
                best available hashing algorithm (argon2id), using a server-wide
                secret pepper and a randomly generated salt. Salting the
                password adds extra flavor… It means if two users stored the
                same password, they would appear completely differently when
                stored. Peppering introduces an extra secret making it one step
                harder for an attacker. We store this hashed password, ready to
                authorize you against later on.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                When you log in, after verifying yourself with email 2FA, we
                take the salt from your hashed password and repeat the process
                with the password you just entered. If the two match, you’re in!
              </p>
            </div>

            <div className="flex w-full flex-col mt-4 gap-2 lg:gap-4">
              <h1 className="whitespace-normal text-xl font-medium text-black">
                Unlocking user storage
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                When your account is created, 3 randomly generated encryption
                keys are created - a local encryption key, a server-side
                encryption key, and a public private key pair. These are used to
                encrypt all your sensitive data. When stored, these keys
                encrypted with one of two key encryption keys (KEK) created from
                your password. This means each time you log in, the KEK can be
                regenerated from the password you enter with the same method,
                and used to decrypt your master keys - unlocking all your items.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                The server-side KEK (used to encrypt your server-side key) is
                generated by hashing your password with the hashing algorithm
                argon2id, a server wide secret pepper and a randomly generated
                salt which was created on account creation, and stored in your
                user instance.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                The local KEK (used to encrypt your local and private keys) is
                generated by hashing your password with the hashing algorithm
                SHA-256, using the accounts email as the salt.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                After logging in your server-side encryption key is decrypted
                then re-encrypted with a session encryption key and stored in a
                sessions cache. The session encryption key is then encrypted
                with a server secret key, packaged into a time limited json web
                token (JWT) and returned to you in a cookie. This cookie is then
                used to authorise you each time you request something, and
                decrypt your items wherever needed, until the JWT expires and
                can no longer be verified. The local encryption key is decrypted
                and stored in either the page's cache, or, for the desktop app,
                re-encrypted using Electron's safeStorage then stored on your
                hard drive. This means it's never accessible to other programs
                or websites.
              </p>
            </div>

            <div className="flex w-full flex-col mt-4 gap-2 lg:gap-4">
              <h1 className="whitespace-normal text-xl font-medium text-black">
                The most important part. Master passwords
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                The weak point of this system is the master password itself. An
                attacker will try to guess passwords over and over until they
                gain access. Having resource-intensive hashing algorithms slows
                an attack down since each guess takes longer - as discussed in
                implementation. However, it's also key to enforce using a
                password which creates enough possible combinations. Therefore,
                Aglide master passwords must be 12 or more characters long, and
                contain at least one number, capital letter, and symbol to be
                valid.
              </p>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1 className="text-left lg:text-4xl text-2xl font-medium text-black">
              3. Implementation
            </h1>
            <p className="text-left lg:text-2xl text-lg text-darkgrey">
              Hashing and encryption functions are never written by Aglide.
              Instead they are invoked from reputable libraries, published
              maintained by large communities of cryptographic experts.
            </p>

            <div className="flex w-full flex-col mt-4 gap-2 lg:gap-4">
              <h1 className="whitespace-normal text-xl font-medium text-black">
                Hashing
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                Both the salts and peppers used in these hashing steps are
                generated with a Cryptographically Secure Pseudorandom Number
                Generator (CSPRNG) and are 128-bit, recommended by the{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/P-H-C/phc-winner-argon2/blob/master/argon2-specs.pdf"
                  className="underline hover:text-primary cursor-pointer"
                >
                  argon2 specs
                </a>
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                Argon2 is the 2015 winner of the{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.password-hashing.net/"
                  className="underline hover:text-primary cursor-pointer"
                >
                  password hashing competition
                </a>{" "}
                and therefore known to be best in class. Argon2id specifically,
                as recommended by{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://cheatsheetseries.owasp.org/cheatsheets/Password_Storage_Cheat_Sheet.html"
                  className="underline hover:text-primary cursor-pointer"
                >
                  OWASP
                </a>
                , is a hybrid variant that balances strong protection against
                two key different types of attacks. For all users, Argon2
                hashing is set to 64 MiB of memory, 3 iterations, and 4 threads
                (m=65536, t=3, p=4). These settings are used for all users and
                are not configurable, so cannot be accidentally reduced by one
                of your employees. These settings are far more
                resource-intensive than the current recommendations from{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://cheatsheetseries.owasp.org/cheatsheets/Password_Storage_Cheat_Sheet.html"
                  className="underline hover:text-primary cursor-pointer"
                >
                  OWASP
                </a>
                , so provide a high level of difficulty for brute force or
                dictionary attacks. SHA-256 is a highly secure hashing aglorithm
                used by many industry leaders, and is implemented using the{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://developer.mozilla.org/en-US/docs/Web/API/Web_Crypto_API"
                  className="underline hover:text-primary cursor-pointer"
                >
                  Web Crypto API
                </a>
                .
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                Hashing functions are invoked from libaraies following the
                published{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/P-H-C/phc-winner-argon2"
                  className="underline hover:text-primary cursor-pointer"
                >
                  Argon2 reference implementation.
                </a>
              </p>
            </div>

            <div className="flex w-full flex-col mt-4 gap-2 lg:gap-4">
              <h1 className="whitespace-normal text-xl font-medium text-black">
                Encryption
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                AES is trusted by the US government, and when correctly
                implemented, and with a strong enough key, it is effectively
                unbreakable. It’s also considered quantum-proof.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                Each encryption key is 256-bit, generated with a
                Cryptographically Secure Pseudorandom Number Generator (CSPRNG).
                The initialisation vector used (iv) is 128-bit generated through
                CSPRNG.{" "}
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                Cryptographic are invoked from{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://nodejs.org/api/crypto.html"
                  className="underline hover:text-primary cursor-pointer"
                >
                  reputable libraries.
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1 className="text-left lg:text-4xl text-2xl font-medium text-black">
              4. Accessing Aglide
            </h1>
            <p className="text-left lg:text-2xl text-lg text-darkgrey">
              When accessing or contacting Aglide, it is important to verify
              that it is really us. To make that as easy as possible, there are
              very few offical Aglide outlets. All online clients are hosted on
              aglide.com and subdomains of it, and there are only three places
              you should ever enter your Aglide master password, any 2FA codes,
              or any other sensitive data:
            </p>

            <div className="max-w-7xl grid lg:grid-cols-3 grid-cols-1 gap-8 align-middle mx-auto my-4">
              <RoundedSection
                title="Aglide Desktop"
                body="The Aglide Desktop app is required for Aglide SSO and can act as the user's SSO launcher. It's available for Windows, Mac, and Linux, and should only be downloaded from the link providered by your customer success agent."
              />
              <RoundedSection
                title="Aglide Extension"
                body="The Aglide Extension acts as the Aglide Password Manager, and is required for Aglide SSO. It's available on the Chrome store (id: dcmjiahhddajbfccpbmcoolndcelloaf)."
              />
              <RoundedSection
                title="Aglide Dashboard"
                body="The Aglide dashboard is where users and admins can manage their accounts and access. It is only accessible at the link dashboard.aglide.com."
              />
            </div>

            <div className="flex w-full flex-col gap-2 lg:gap-4">
              <h1 className="whitespace-normal text-xl font-medium text-black">
                How we will contact you
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                The main Aglide domain is aglide.com. When we contact you, it
                will most likely be from support@aglide.com, however we do own
                more domains that we could contact you from. These are:
                <ul className="list-disc ml-8">
                  <li>aglide.io</li>
                  <li>aglide.co</li>
                  <li>aglide.ai</li>
                  <li>aglide.co.uk</li>
                  <li>aglide.xyz</li>
                  <li>aglidesso.com</li>
                  <li>tryaglide.com</li>
                  <li>aglidehq.com</li>
                </ul>
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                We will never ask for passwords or 2FA codes from you. Never
                provide your master password or any 2FA codes to anyone, even if
                it appears to be communication from a legitimate source. If you
                believe someone is attempting to impersonate us, please notify
                us by emailing report@aglide.com asap, so we can notify other
                users.
              </p>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1 className="text-left lg:text-4xl text-2xl font-medium text-black">
              5. Handling emails
            </h1>
            <p className="text-left lg:text-2xl text-lg text-darkgrey">
              Each user is given an email address, linked to their work email address. Aglide's Zero Trust model means very little is known about who owns each email, besides what address it is linked to.
            </p>
            <p className="text-left lg:text-2xl text-lg text-darkgrey">
              When emails are sent to these addresses, Aglide determines if they could support SSO enforcement. If they can't, they are automatically forwarded to the linked email address. If they can, they're encrypted with zero trust, end-to-end, public key cryptography, and temporarily stored.
            </p>
            <p className="text-left lg:text-2xl text-lg text-darkgrey">
              Aglide has never, and will never, bulk store and analyse your emails. It is impossible for Aglide, or anyone else for that matter, to read your stored emails.
            </p>

            <div className="flex w-full flex-col mt-4 gap-2 lg:gap-4">
              <h1 className="whitespace-normal text-xl font-medium text-black">
                Processing
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                Aglide email addresses are of the format [anonomousID]@aglidesso.com. When an email is sent to this address, the anonomous ID is used to recover the linked email address and public key. Email addresses are administratively encrypted at rest.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                When emails are send to these addresses, they are checked against known emails that could support SSO enforcement. If there is a match, the email is encrypted, temporarily stored, and potentially forwarded to the user's workspace admin. If not, the email is forwarded to the linked email address, and not stored.
              </p>
            </div>

            <div className="flex w-full flex-col mt-4 gap-2 lg:gap-4">
              <h1 className="whitespace-normal text-xl font-medium text-black">
                The purpose of Aglide email addresses
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                Aglide Desktop is better able to perform certain functions (e.g., SSO, AutoMigration, etc.) when it has access to data from certain emails (e.g., confirmation links). As a result, relevant emails are stored so the Aglide Desktop app can access them when running these processes. As soon as an email is recovered by the app, it's deleted.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                While your authenticated Aglide desktop app may need these emails, Aglide does not. That is why we are very selective about the emails we store, we store them in a way that ensures only the app signed in to by you can access them, and they are only stored temporarily.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                It is impossible for Aglide, or anyone else for that matter, to read your stored emails.
              </p>
            </div>

            <div className="flex w-full flex-col mt-4 gap-2 lg:gap-4">
              <h1 className="whitespace-normal text-xl font-medium text-black">
                Encryption and Temporary Storage
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                Each Aglide user has a public and private key pair. The private key is encrypted with the user's local key, meaning any data encrypted with the public key can only be decrypted by the user on their local machine (see section 2).
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                The main body of the email is encrypted with the user's public key using the RSA-OAEP standard. To allow for search, the subject, sender, and email category (e.g., password reset) are encrypted with the administrative key. The email is then temporarily stored in case it's needed for a local process, and deleted as soon as it is recovered by the desktop app.
              </p>
            </div>

          </div>
        </div>

        <div
          className={`bg-primary5 rounded-2xl p-6 md:p-14 flex flex-col gap-16 h-fit max-w-5xl mx-auto md:flex-row w-full mb-16`}
        >
          <div className="flex-1 order-1">
            <h1 className="text-left lg:text-3xl text-xl font-medium text-black mb-2">
              Questions? Get in touch.
            </h1>
            <p className="text-left text-l text-darkgrey">
              If you have any questions about our security, or you have something to report, please get in touch at{" "}
              <a href="mailto:security@aglide.com">security@aglide.com</a>. We
              are incredibly open to feedback and suggestions, and we want to
              make sure you feel safe using Aglide.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Security;
